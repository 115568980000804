import React from "react";
import _ from "lodash";
import { Input, Card } from "@components";
import { Button, Select, Tooltip } from "antd";
import { DeleteOutlined, HomeOutlined, CodeOutlined } from "@ant-design/icons";
import "./style.scss";

const TOOLTIP_ADD_CODE_TITLE = "Adding an Employee?";
const TOOLTIP_ADD_CODE_MSG =
  "Adding employees takes too much time, just include 12 months of w2 payroll, compensation, commission, and bonuses in the field for all employees doing the same type of work. Be sure to include subcontractor labor expenses that do not have insurance or that you are not monitoring.";
export default function BlockPayroll({
  data,
  locationList,
  onChange,
  disabled
}) {
  function handleChangePayrollItem(index, itemIndex, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].payrollItem[itemIndex][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleChangePayrollInfo(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleAddNewLocation(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal.push({
      locationIndex: null,
      payrollItem: []
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteLocation(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function handleAddNewPayroll(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].payrollItem.push({
      class_code: "",
      desc: "",
      annual_payroll: "",
      full_time_emp: "",
      part_time_emp: ""
    });
    onChange((prev) => newFormVal);
  }

  function handleDeletePayroll(index, itemIndex) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].payrollItem.splice(itemIndex, 1);
    onChange((prev) => newFormVal);
  }

  function renderPayrollItem(payrollItem, index, itemIndex) {
    return (
      <div className="block_pay-item-sub">
        <div className="form-group-five-container">
          <Input
            title="Class Code"
            type="text"
            value={payrollItem.class_code}
            required
            onChange={(evt) =>
              handleChangePayrollItem(
                index,
                itemIndex,
                "class_code",
                evt.target.value
              )
            }
          />
          <Input
            title="Description"
            type="text"
            value={payrollItem.desc}
            required
            onChange={(evt) =>
              handleChangePayrollItem(
                index,
                itemIndex,
                "desc",
                evt.target.value
              )
            }
          />
          <Input
            title="Annual Payroll"
            type="text"
            value={payrollItem.annual_payroll}
            required
            onChange={(evt) =>
              handleChangePayrollItem(
                index,
                itemIndex,
                "annual_payroll",
                evt.target.value
              )
            }
          />
          <Input
            title="Full-Time Employee #"
            type="text"
            value={payrollItem.full_time_emp}
            required
            onChange={(evt) =>
              handleChangePayrollItem(
                index,
                itemIndex,
                "full_time_emp",
                evt.target.value
              )
            }
          />
          <Input
            title="Part-Time Employee #"
            type="text"
            value={payrollItem.part_time_emp}
            required
            onChange={(evt) =>
              handleChangePayrollItem(
                index,
                itemIndex,
                "part_time_emp",
                evt.target.value
              )
            }
          />
        </div>
        <div className="block_pay-item-sub-action">
          {!disabled && (
            <Button
              className="block_pay-item-sub-action-btn"
              onClick={() => handleDeletePayroll(index, itemIndex)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  function renderPayrollInfo(index, payroll) {
    return (
      <div key={`payroll-${index}`} className="block_pay-item">
        <div className="block_pay-item-con">
          <div className="block_pay-item-loc">
            <p className="input-title">
              Select A Physical Location (No Billing/Mailing Address)
            </p>
            <div className="block_pay-item-loc-select">
              <Select
                size="large"
                style={{ minWidth: "300px" }}
                options={locationList}
                value={payroll.locationIndex}
                onSelect={(evt) =>
                  handleChangePayrollInfo(index, "locationIndex", evt)
                }
              ></Select>
            </div>
          </div>
          {payroll.payrollItem.map((payrollItem, payrollIndex) =>
            renderPayrollItem(payrollItem, index, payrollIndex)
          )}
          <div className="block_pay-item-footer">
            {!disabled && (
              <div className="admin_franchisee_under-section-footer">
                <Tooltip
                  title={`${TOOLTIP_ADD_CODE_TITLE} ${TOOLTIP_ADD_CODE_MSG}`}
                >
                  <Button
                    className="admin_franchisee_under-section-footer-link"
                    onClick={() => handleAddNewPayroll(index)}
                  >
                    <CodeOutlined />
                    Add Code
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="block_pay-item-action">
          {!disabled && (
            <Button
              className="block_pay-item-action-btn"
              onClick={() => handleDeleteLocation(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_pay">
      {data.map((payroll, index) => renderPayrollInfo(index, payroll))}
      {!disabled && (
        <div className="block_pay-footer">
          <Button
            className="block_pay-footer-link"
            onClick={handleAddNewLocation}
          >
            <HomeOutlined />
            Add Address
          </Button>
        </div>
      )}
    </div>
  );
}
